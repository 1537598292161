<div class="ui stackable grid">
  <div class="row">
    <div class="eleven wide column">
      <div class="ui card">
        <div class="ui grid secondary pointing menu" style="padding:20px;width:100%">
          <a class="item" data-target="#" (click)="changeTab(true)" [class.active]="showTab1==true">{{'TEMPLATES' | translate}}</a>
          <a class="item" data-target="#" (click)="changeTab(false)" [class.active]="showTab2==true">{{'OWN_DOCUMENTS' | translate}}</a>
        </div>
        <div *ngIf="showTab1==true">
          <table class="ui table" style="padding:20px;padding-top:0px;border:0" *ngIf="managementSystemModel&&managementSystemModel.length>0">
            <tbody>
              <tr *ngFor="let managementSystemModelItem of managementSystemModel;let i=index">
                <td ><a (click)="openDocumentInNewWindow(managementSystemModelItem.filename)" style="color:#1a4d28">
                  {{managementSystemModelItem.filedescription}}
                </a></td>
                <td></td>
                <td></td>
                <td></td>
                <td (click)="removeItem(managementSystemModelItem,i)" *ngIf="is_admin||is_super_user" style="cursor:pointer"><i class="ui icon red trash"></i>
                </td>
                <input type="hidden" value="{{managementSystemModelItem.placement}}" />
              </tr>
            </tbody>
          </table>
          <button *ngIf="is_admin || is_super_user" class="ui positive button" (click)="add_document()" style="margin-left:20px;margin-bottom:10px"><i class="ui icon add"></i>{{'ADD_DOCUMENT' | translate}}</button>
        </div>
        <div *ngIf="showTab2==true">
          <table class="ui table" style="padding:20px;padding-top:0px;border:0" *ngIf="managementSystemModel&&managementSystemModel.length>0">
            <tbody>
              <tr *ngFor="let managementSystemModelItem of managementSystemModel;let i=index">
                <td ><a (click)="openDocumentInNewWindow(managementSystemModelItem.filename)">
                  {{managementSystemModelItem.filedescription}}
                </a></td>
                <td></td>
                <td></td>
                <td></td>
                <td (click)="removeItem(managementSystemModelItem,i)" *ngIf="is_admin||is_super_user" style="cursor:pointer"><i class="ui icon red trash"></i>
                </td>
                <input type="hidden" value="{{managementSystemModelItem.placement}}" />
              </tr>
            </tbody>
          </table>
          <button *ngIf="is_admin || is_super_user" class="ui positive button" (click)="add_document()" style="margin-left:20px;margin-bottom:20px;margin-top:0"><i class="ui icon add"></i>{{'ADD_TEMPLATE' | translate}}</button>
        </div>
      </div>
    </div>

    <div class="five wide column">
      <div class="ui grid rectangle-green" >
        <div class="row">
          <div class="sixteen wide column">
            <i style="color: white;margin-left:20px;margin-bottom:10px" class="ui icon large circle info" aria-hidden="true"></i><br/>
            <br/><b style="color:white;margin-left:20px;font-weight:800">{{'THE_LAW_HEADER' | translate}}</b>
            <br/><br/>
            <p style="color:white;margin-left:20px">
              {{'THE_LAW_TEXT_1' | translate}}
            </p>
            <p style="color:white;margin-left:20px">
              {{'THE_LAW_TEXT_2' | translate}}
            </p>
            <p style="color:white;margin-left:20px">
              {{'THE_LAW_TEXT_3' | translate}}<br/>
            </p>
            <div class="link-rectangle" >
              <a style="color:white" href="https://www.av.se/" target="_blank">
                {{'THE_LAW_TEXT_4' | translate}}
              </a>
            </div>
            <div class="link-rectangle">
              <a style="color:white" href="https://www.av.se/arbetsmiljoarbete-och-inspektioner/publikationer/checklistor/" target="_blank">
                {{'THE_LAW_TEXT_5' | translate}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
