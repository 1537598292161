<div class="ui stackable grid">
    <div class="row">
        <div class="eleven wide column">
            <div class="ui card">
                <div class="card card-help">
                    <div class="card card-help2">
                        <div class="help2-info">
                            <h2>{{'safetyrounds'|translate}}</h2>
                            {{'HELP_TEXT_SAFETYROUNDS_1' | translate}} {{'HELP_TEXT_SAFETYROUNDS_2' | translate}}
                            <br><br>
                            {{'HELP_TEXT_SAFETYROUNDS_3' | translate}} {{'HELP_TEXT_SAFETYROUNDS_4' | translate}}
                            <br><br>
                            {{'HELP_TEXT_SAFETYROUNDS_2_1' | translate}} {{'HELP_TEXT_SAFETYROUNDS_2_2' | translate}}
                            {{'HELP_TEXT_SAFETYROUNDS_2_3' | translate}}
                        </div>
                    </div>
                    <hr/>
                    <div class="card card-help2">
                        <div class="help2-info">
                            <h2>{{'incidents'|translate}}</h2>
                            {{'HELP_TEXT_INCIDENTROUNDS_1' | translate}}
                            <br><br>
                            {{'HELP_TEXT_INCIDENTROUNDS_2' | translate}}
                            <br><br>
                            {{'HELP_TEXT_INCIDENTROUNDS_3' | translate}}
                        </div>
                    </div>
                    <hr/>
                    <div class="card card-help2">
                        <div class="help2-info">
                            <h2>{{'actionplan'|translate}}</h2>
                            {{'HELP_TEXT_ACTIONPLAN_1' | translate}}
                            <br><br>
                            {{'HELP_TEXT_ACTIONPLAN_2' | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="five wide column">
            <div class="rectangle-green">
                <div class="green-header">
                    2.1.0.1
                </div>
                <div class="green-text">
                    {{'version'|translate}}
                </div>
                <div class="green-header">
                    035-108189
                </div>
                <div class="green-text">
                    {{'phone'|translate}}
                </div>
                <div class="green-header">
                    support@samtec.se
                </div>
                <div class="green-text">
                    {{'support'|translate}}
                </div>
            </div>
        </div>
    </div>
</div>