import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "src/app/auth/auth.service";
import { MessageService } from "src/app/shared/services/message.service";
import { take,concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  username: any;
  companyModel: any;
  epost: any;
  firstname: any;
  lastname: any;
  phone: any;
  depname: any;
  token: any;
  static_token: any;
  userlevel: any;
  password: any;
  orgmodel: any;
  is_admin: any;
  is_super_user: any;
  orgId: any;
  static_token_text: any;

  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private authService: AuthService,
    private msgService: MessageService,
    private routing: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.route.params.pipe(take(1)).subscribe(params => {
      this.orgId = params['id'];

      this.is_admin=this.authService.getAdmin();
      this.is_super_user=this.authService.getSuperUser();

      this.dataService.getOrganisationSchemaControlPanel(this.orgId);
      this.dataService.orgModelListenerControlPanel()
      .pipe(take(1))
      .subscribe((orgmodel: any) => {
        this.orgmodel = orgmodel;
        this.depname = this.orgmodel[0].Name;
      });

      this.userlevel='2';

    });

    this.msgService.sendMessage(null);
  }

  generateToken(){
    this.static_token = uuidv4();
    if (environment.tokentext == "test")
    {
      this.static_token_text = 'http://test.samtec.se/#/login?access_token=' + this.static_token;
    }
    else
    {
      this.static_token_text = 'http://portal.samtec.se/#/login?access_token=' + this.static_token;
    }
  }

  addItem() {

    this.companyModel=new Array({
      'epost':this.epost,
      'firstname':this.firstname,
      'lastname':this.lastname,
      'phone':this.phone,
      'department':this.depname,
      'token':this.token,
      'static_token':this.static_token,
      'userlevel':this.userlevel,
      'username':this.username,
      'password':this.password,
      'organisation_id':this.orgId,
    });

    for(let i = 0; i < this.orgmodel.length; i++)
    {
      if (this.orgmodel[i].Name == this.depname)
      {
        this.companyModel[0].department_id = this.orgmodel[i].Id;
      }
    }
    this.dataService.insertUserInfo(this.companyModel, 0);
  }

  goBack() {
    this.routing.navigate(['/controlpanel/organisations']);
  }
}
